import { render } from "lit-html"
import {
  FileInput,
  GeocodeInput,
  NestedDocumentFormInput,
  tagBlocks,
  ConditionalInput,
  AutocompleteText,
  renderRadioSelectOtherSpecify,
  renderCheckboxOtherSpecify,
  renderAutocomplete,
  renderEmbedAutocomplete,
  renderKeywordInput,
  renderTimeRange,
  renderOccurrenceInput,
} from "../inputs"

export default function FormInputs(formEl) {
  formEl
    .querySelectorAll("select[data-autocomplete]")
    .forEach(renderAutocomplete)
  formEl
    .querySelectorAll("select[data-embed-autocomplete]")
    .forEach(renderEmbedAutocomplete)
  formEl.querySelectorAll("input[data-keyword]").forEach(renderKeywordInput)
  formEl.querySelectorAll("input[data-managers-lookup]").forEach((el) => {
    render(
      AutocompleteText({
        name: el.name,
        value: el.value || ``,
        required: el.hasAttribute("required"),
        placeholder:
          el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
        endpoint: el.dataset["endpoint"],
        onSelect: () => {
          setTimeout(() => {
            const $addBtn = document.querySelector(
              "#program-create-managers form button.add-btn"
            )
            $addBtn.click()
          }, 0)
        },
        onResults: ({ results }) =>
          results.map(({ email, result }) => ({
            ...result,
            value: email,
            label: email,
          })),
        noResultsText: "",
      }),
      el.parentNode
    )
  })
  formEl
    .querySelectorAll(`[data-component="file-input"]`)
    .forEach((el) => FileInput(el.parentNode))
  formEl.querySelectorAll("input[data-geocoder]").forEach(GeocodeInput)
  formEl
    .querySelectorAll("[data-action='nested-document-input']")
    .forEach(NestedDocumentFormInput)
  formEl
    .querySelectorAll(`[data-component="time-range-input"]`)
    .forEach(renderTimeRange)
  formEl.querySelectorAll(".tag-filter-input").forEach(tagBlocks)
  formEl.querySelectorAll(".other-specify").forEach(renderCheckboxOtherSpecify)
  formEl
    .querySelectorAll(".single-other-specify")
    .forEach(renderRadioSelectOtherSpecify)
  formEl.querySelectorAll("[data-condition-input]").forEach(ConditionalInput)
  formEl.querySelectorAll("[data-occurrence]").forEach(renderOccurrenceInput)

  if (formEl.action.includes("/meetings/create/")) {
    document.addEventListener("autocomplete-agency:updated", (e) => {
      fetch(`/agencies/${e.detail.value}/programs/api`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const programIds = res.programs.map(({ id }) => id)
          formEl
            .querySelectorAll("input[name='programs']")
            .forEach(($programCheckBox) => {
              $programCheckBox.checked = programIds.includes(
                parseInt($programCheckBox.value)
              )
            })

          const programTimezones = res.programs.map(({ timezone }) => timezone)
          const $timezoneInput = formEl.querySelector("input[id='id_timezone']")
          $timezoneInput.value =
            new Set(programTimezones).size === 1 ? programTimezones[0] : ""
        })
    })
  }
}
