import { render } from "lit-html"

import { BING_MAPS_KEY, US_STATES_TO_ABBREV } from "../constants"

import { AutocompleteText } from "./autocomplete-text"

const GEOCODE_ENDPOINT = `https://dev.virtualearth.net/REST/v1/Autosuggest?key=${BING_MAPS_KEY}&ul=41.876,-87.65&query=`

const onResults = (res) =>
  res.resourceSets[0].resources[0].value.map((r) => ({
    ...r.address,
    value: r.address.formattedAddress,
    label: r.address.formattedAddress,
  }))

export default function GeocodeInput(el) {
  const mount = el.parentNode
  const cityInput = document.querySelector(`input[name="city"]`)
  const stateInput = document.querySelector(`select[name="state"]`)
  const zipInput = document.querySelector(`input[name="zip_code"]`)
  render(
    AutocompleteText({
      name: el.name,
      value: el.value || ``,
      required: el.hasAttribute("required"),
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      endpoint: GEOCODE_ENDPOINT,
      onResults,
      onSelect: (item) => {
        const stateAbbrev =
          item.adminDistrict in US_STATES_TO_ABBREV
            ? US_STATES_TO_ABBREV[item.adminDistrict]
            : ""
        if (cityInput) cityInput.value = item.locality || ""
        if (stateInput && stateAbbrev) stateInput.value = stateAbbrev
        if (zipInput) zipInput.value = item.postalCode || ""
      },
    }),
    mount
  )
}
