import { addToastMessage } from "../generic/messages"
import { ERROR_MESSAGE } from "../constants"

/**
 * Submits a form via AJAX and returns a JSON promise
 * @param {element} form
 */
export default function ajaxFormSubmit(form) {
  const formData = new FormData(form)
  return fetch(form.action, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: formData,
  })
    .then((res) => (res.status === 200 ? res.json() : res))
    .catch((err) => {
      console.error(err)
      addToastMessage(ERROR_MESSAGE, "error toast-modal")
      return Promise.resolve()
    })
}
