import FileInput from "./file"
import ajaxFormSubmit from "../forms/ajax"
import ModalForm from "../forms/modal"
import { html, render } from "lit-html"
import { DOC_ICON_MAP } from "../constants"
import { addToastMessage } from "../generic/messages"

function addDocumentRow({ pk: value, label: name, link, category }, container) {
  const docRow = document.createElement("div")
  container.appendChild(docRow)

  render(
    html`<div
      data-section="document-row"
      class="flex flex-row items-center justify-between p-4"
    >
      <input type="hidden" name="documents" value="${value}" />
      <p>
        <a href="${link}" target="_blank" rel="noopener noreferrer">
          <i class="${DOC_ICON_MAP[category] || "fas fa-file"}"></i>
          <span class="ml-3">${name}</span>
        </a>
      </p>
      <button
        type="button"
        class="delete"
        aria-label="Delete"
        data-action="remove-document"
        data-value="${value}"
      ></button>
    </div>`,
    docRow
  )

  docRow
    .querySelector(`[data-action="remove-document"]`)
    .addEventListener("click", () => removeDocumentRow(value))
}

function removeDocumentRow(value) {
  const docInput = document.querySelector(
    `input[name="documents"][value="${value}"]`
  )
  docInput.parentNode.parentNode.removeChild(docInput.parentNode)
}

export default function NestedDocumentFormInput(el) {
  const modalMount = document.getElementById("modal-mount")

  el.querySelectorAll(`button[data-action="nested-document-modal"]`).forEach(
    (btn) => {
      const onCreate = () => {
        const modalForm = modalMount.querySelector("form")
        const csrftoken = document
          .querySelector('input[name="csrfmiddlewaretoken"]')
          .cloneNode(true)
        modalForm.appendChild(csrftoken)
        modalForm
          .querySelectorAll(`[data-component="file-input"]`)
          .forEach((el) => FileInput(el.parentNode))
      }

      const onSubmit = () => {
        ajaxFormSubmit(modalMount.querySelector("form")).then((res) => {
          if (res.ok === false) {
            res.json().then((err) => {
              const errors = Object.values(err).join(". ")
              addToastMessage(
                `Error uploading document: ${errors}`,
                "error toast-modal"
              )
            })
          } else {
            addDocumentRow(
              res,
              el.querySelector(`[data-section="document-row-container"]`)
            )
          }
        })
      }

      ModalForm(btn, null, onCreate, onSubmit)
    }
  )

  el.querySelectorAll("button[data-action='remove-document']").forEach(
    (btn) => {
      btn.addEventListener("click", () => {
        removeDocumentRow(btn.dataset.value)
      })
    }
  )
}
