import { html, useEffect, virtual } from "haunted"

import Modal from "./modal"

const HXForm = virtual(({ name, action, onAfterReq, onCancel }) => {
  const formId = `${name}-hx-form`

  useEffect(() => {
    const $form = document.getElementById(formId)
    window.htmx.process($form)

    document.addEventListener("htmx:afterRequest", () => {
      onAfterReq()

      const $cancelBtn = document.querySelector(
        `#${formId} button[type='reset']`
      )
      $cancelBtn.addEventListener("click", (e) => {
        e.preventDefault()
        onCancel()
      })
    })
  })

  return html`<div
    id="${formId}"
    hx-get=${action}
    hx-target="#${formId}"
    hx-swap="outerHTML"
    hx-trigger="load"
  ></div> `
})

const HXModal = ({
  name,
  action,
  labelledBy,
  onClose,
  onAfterReq = () => {},
}) => {
  return Modal({
    children: HXForm({
      name,
      action,
      onAfterReq,
      onCancel: onClose,
    }),
    labelledBy,
    onClose,
  })
}

export default HXModal
