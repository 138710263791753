import { html, render } from "lit-html"
import { virtual, useEffect, useState, useRef } from "haunted"
import { repeat } from "lit-html/directives/repeat"
import { ref } from "../directives"
import Modal from "../generic/modal"

const DAILY = "Daily"
const WEEKLY = "Weekly"
const MONTHLY = "Monthly"

const RECURRING_TEXT_MAPPING = {
  [DAILY]: "day",
  [WEEKLY]: "week",
  [MONTHLY]: "month",
}

const RECURRING_WEEKDAY_CHOICES = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
}

const RECURRING_WEEK_OF_MONTH_CHOICES = {
  0: "first",
  1: "second",
  2: "third",
  3: "fourth",
  "-1": "last",
}

const MONTHLY_BY_DAY = "by_day"
const MONTHLY_BY_WEEK = "by_week"
const END_BY_DAY = "by end day"

const DEFAULT_OCCURRENCE_VALUE = {
  recurring_type: null,
  separation_count: 1,
  day_of_week: null,
  week_of_month: null,
  day_of_month: null,
  end_date: null,
}

const nthDateOfMonth = (day) => {
  const s = ["th", "st", "nd", "rd"]
  return day + (s[(day - 20) % 10] || s[day] || s[0])
}

const formatDate = (dateStr) => {
  const date = new Date(`${dateStr}T00:00:00Z`)
  return date
    .toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    })
    .replace(/^(?<MMM>\w{3})/, "$<MMM>.")
}

const MultipleSelectValues = ({ selectedOptions, onDelete }) => html`
  ${repeat(
    selectedOptions,
    ({ value }) => value,
    ({ value, label }) =>
      html`<div>
        <span
          class="inline-flex flex-row items-center bg-white border-px border-gray-border rounded-md px-4 py-2 mr-1 my-1"
          >${label}
          <button
            @click=${() => onDelete(value)}
            @keydown=${(e) => {
              if (e.code === "Enter") onDelete(value)
            }}
            type="button"
            class="delete ml-2"
            aria-label="Remove ${label}"
          ></button>
        </span>
      </div>`
  )}
`

const CustomOccurrenceModal = virtual(({ onClose, onSubmit }) => {
  const today = new Date().toISOString().split("T")[0]

  const [separationCount, setSeparationCount] = useState(2)
  const [recurringType, setRecurringType] = useState(WEEKLY)
  const [monthlyRecurrence, setMonthlyRecurrence] = useState(MONTHLY_BY_DAY)
  const [dayOfWeek, setDayOfWeek] = useState(0)
  const [weekOfMonth, setWeekOfMonth] = useState(0)
  const [dayOfMonth, setDayOfMonth] = useState(1)
  const [endTime, setEndTime] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endDateErrMessage, setEndDateErrMessage] = useState("")

  useEffect(() => {
    setEndDateErrMessage("")
    if (endTime === END_BY_DAY) {
      if (endDate === null || endDate === "") {
        setEndDateErrMessage("Please input value for end date")
      } else if (endDate < today) {
        setEndDateErrMessage("End date must be greater or equal to today")
      }
    }
  }, [endTime, endDate])

  const getOption = () => {
    let label =
      separationCount > 1
        ? `Every ${separationCount} ${RECURRING_TEXT_MAPPING[recurringType]}s`
        : recurringType
    let value = {
      ...DEFAULT_OCCURRENCE_VALUE,
      separation_count: parseInt(separationCount),
      recurring_type: recurringType,
    }
    if (recurringType === WEEKLY) {
      label += ` on ${RECURRING_WEEKDAY_CHOICES[dayOfWeek]}`
      value["day_of_week"] = parseInt(dayOfWeek)
    } else if (recurringType === MONTHLY) {
      if (monthlyRecurrence === MONTHLY_BY_DAY) {
        label += ` on the ${nthDateOfMonth(dayOfMonth)}`
        value["day_of_month"] = parseInt(dayOfMonth)
      } else {
        label += ` on ${RECURRING_WEEKDAY_CHOICES[dayOfWeek]} of the ${RECURRING_WEEK_OF_MONTH_CHOICES[weekOfMonth]} week`
        value["week_of_month"] = parseInt(weekOfMonth)
        value["day_of_week"] = parseInt(dayOfWeek)
      }
    }
    if (endTime == END_BY_DAY && endDate !== null) {
      label += `, until ${formatDate(endDate)}`
      value["end_date"] = endDate
    }

    return { label, value: JSON.stringify(value) }
  }

  const onDone = () => {
    if (endDateErrMessage === "") {
      const option = getOption()
      onSubmit(option)
      onClose()
    }
  }

  return html`
    ${Modal({
      onClose,
      children: html`
        <div
          class="modal-content custom-occurrence-modal rounded-md bg-white text-black"
        >
          <h1 class="p-7 border-b-px border-gray-border text-xl font-serif">
            Custom recurrence
          </h1>
          <div class="px-6 py-8">
            <div class="flex flex-wrap items-center">
              <span class="mr-5">Repeat every</span>
              <input
                type="number"
                class="mr-5 my-2 w-12 h-10 border-gray-border text-center"
                min="1"
                .value=${separationCount}
                @change=${(e) => setSeparationCount(e.target.value)}
              />
              <select
                class="my-2 w-15 h-10 border-gray-border px-6"
                @change=${(e) => setRecurringType(e.target.value)}
              >
                <option value=${DAILY} ?selected=${recurringType === DAILY}>
                  days
                </option>
                <option value=${WEEKLY} ?selected=${recurringType === WEEKLY}>
                  weeks
                </option>
                <option value=${MONTHLY} ?selected=${recurringType === MONTHLY}>
                  months
                </option>
              </select>
            </div>
            ${recurringType === WEEKLY
              ? html` <div class="mt-8 flex items-center">
                  <span class="mr-5">On</span>
                  <select
                    class="mr-5 my-2 w-15 h-10 border-gray-border px-6"
                    @change=${(e) => setDayOfWeek(e.target.value)}
                  >
                    ${repeat(
                      Object.entries(RECURRING_WEEKDAY_CHOICES),
                      (entry) => entry[0],
                      ([value, label]) => html`
                        <option value=${value} ?selected=${dayOfWeek === value}>
                          ${label}
                        </option>
                      `
                    )}
                  </select>
                </div>`
              : ``}
            ${recurringType === MONTHLY
              ? html` <div class="mt-8 flex items-center">
                    <input
                      type="radio"
                      style="margin-right:10px"
                      name="monthly_recurrence"
                      value=${MONTHLY_BY_DAY}
                      ?checked=${monthlyRecurrence === MONTHLY_BY_DAY}
                      @change=${() => setMonthlyRecurrence(MONTHLY_BY_DAY)}
                    />
                    <div class="flex flex-wrap items-center">
                      <span class="mr-5">Day</span>
                      <input
                        type="number"
                        class="mr-5 my-2 w-12 h-10 border-gray-border text-center"
                        min="1"
                        max="31"
                        ?disabled=${monthlyRecurrence !== MONTHLY_BY_DAY}
                        .value=${dayOfMonth}
                        @change=${(e) => setDayOfMonth(e.target.value)}
                      />
                      <span>of the month</span>
                    </div>
                  </div>
                  <div class="mt-8 flex items-center">
                    <input
                      type="radio"
                      style="margin-right:10px"
                      name="monthly_recurrence"
                      value=${MONTHLY_BY_WEEK}
                      ?checked=${monthlyRecurrence === MONTHLY_BY_WEEK}
                      @change=${() => setMonthlyRecurrence(MONTHLY_BY_WEEK)}
                    />
                    <div class="flex flex-wrap items-center">
                      <span class="mr-5">On</span>
                      <select
                        class="mr-5 my-2 w-15 h-10 border-gray-border px-6"
                        ?disabled=${monthlyRecurrence !== MONTHLY_BY_WEEK}
                        @change=${(e) => setDayOfWeek(e.target.value)}
                      >
                        ${repeat(
                          Object.entries(RECURRING_WEEKDAY_CHOICES),
                          (entry) => entry[0],
                          ([value, label]) => html`
                            <option
                              value=${value}
                              ?selected=${dayOfWeek === value}
                            >
                              ${label}
                            </option>
                          `
                        )}
                      </select>
                      <span class="mr-5">of the</span>
                      <select
                        class="mr-5 my-2 w-15 h-10 border-gray-border px-6"
                        ?disabled=${monthlyRecurrence !== MONTHLY_BY_WEEK}
                        @change=${(e) => setWeekOfMonth(e.target.value)}
                      >
                        ${repeat(
                          Object.entries(RECURRING_WEEK_OF_MONTH_CHOICES),
                          (entry) => entry[0],
                          ([value, label]) => html`
                            <option
                              value=${value}
                              ?selected=${weekOfMonth === value}
                            >
                              ${label}
                            </option>
                          `
                        )}
                      </select>
                      <span>week</span>
                    </div>
                  </div>`
              : ``}
            <div class="mt-8">
              <div>Ends</div>
              <div class="mt-8 flex items-center">
                <input
                  type="radio"
                  style="margin-right:10px"
                  name="end_time"
                  value=${null}
                  ?checked=${endTime === null}
                  @change=${() => setEndTime(null)}
                />
                <span>Never</span>
              </div>
              <div class="mt-8 flex items-center">
                <input
                  type="radio"
                  style="margin-right:10px"
                  name="end_time"
                  value=${END_BY_DAY}
                  ?checked=${endTime === END_BY_DAY}
                  @change=${() => setEndTime(END_BY_DAY)}
                />
                <span class="mr-5">On</span>
                <input
                  type="date"
                  class="mr-5 my-2 w-200 h-10 border-gray-border"
                  min=${today}
                  ?disabled=${endTime !== END_BY_DAY}
                  .value=${endDate}
                  @change=${(e) => setEndDate(e.target.value)}
                />
              </div>
              <span class="block ml-7 pl-3 text-red">${endDateErrMessage}</span>
            </div>
          </div>
          <div class="p-7 border-t-px border-gray-border flex justify-end">
            <button
              type="button"
              @click=${onClose}
              class="mr-4 rounded border-px border-gray-boder px-8 py-4"
            >
              Cancel
            </button>
            <button
              type="button"
              @click=${onDone}
              ?disabled=${!!endDateErrMessage}
              class="rounded px-8 py-4 ${endDateErrMessage
                ? `bg-gray`
                : `bg-blue`} text-white"
            >
              Done
            </button>
          </div>
        </div>
      `,
    })}
  `
})

const OccurrenceInput = virtual(({ name, placeholder, options }) => {
  const containerRef = useRef(null)
  const [didMount, setDidMount] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState(-1)

  const [openOccurrenceModal, setOpenOccurrenceModal] = useState(false)

  const [selectOptions, setSelectOptions] = useState(options)
  const [selectedOptions, setSelectedOptions] = useState(
    selectOptions.filter(({ selected: optionSelected }) => optionSelected)
  )
  const visibleOptions = selectOptions.filter(
    ({ value: optionValue }) =>
      !selectedOptions.map(({ value }) => value).includes(optionValue)
  )

  const updateOptions = (startDate) => {
    if (startDate) {
      const date = new Date(startDate)
      const day = date.getUTCDate()
      const month = date.getUTCMonth()
      const year = date.getUTCFullYear()
      const weekday = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        timeZone: "GMT",
      }).format(date)
      const lastDayOfMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate()

      const dayOfWeek = (date.getUTCDay() + 6) % 7
      // Calculate number of a weekday in a month by dividing it into 3 parts with current date as pivot.
      const numberOfWeekdays =
        Math.floor((day - 1) / 7) + Math.floor((lastDayOfMonth - day) / 7) + 1
      // Adjust week of the month in zero-based index and sets it to -1 if it's the last week of the month.
      let weekOfMonth = Math.ceil(day / 7)
      weekOfMonth = weekOfMonth == numberOfWeekdays ? -1 : weekOfMonth - 1

      const [option1, option2, option3, option4, ...rest] = options
      const topOptions = [
        option1,
        {
          ...option2,
          label: `Weekly on ${weekday}`,
          value: JSON.stringify({
            ...DEFAULT_OCCURRENCE_VALUE,
            ...JSON.parse(option2.value),
            recurring_type: WEEKLY,
            day_of_week: dayOfWeek,
          }),
        },
        {
          ...option3,
          label: `Every 2 weeks on ${weekday}`,
          value: JSON.stringify({
            ...DEFAULT_OCCURRENCE_VALUE,
            ...JSON.parse(option3.value),
            recurring_type: WEEKLY,
            separation_count: 2,
            day_of_week: dayOfWeek,
          }),
        },
        {
          ...option4,
          label: `Monthly on the ${nthDateOfMonth(day)}`,
          value: JSON.stringify({
            ...DEFAULT_OCCURRENCE_VALUE,
            ...JSON.parse(option4.value),
            recurring_type: MONTHLY,
            day_of_month: day,
          }),
        },
        {
          label: `Monthly on ${weekday} of the ${
            ["first", "second", "third", "fourth"][weekOfMonth] || "last"
          } week`,
          value: JSON.stringify({
            ...DEFAULT_OCCURRENCE_VALUE,
            recurring_type: MONTHLY,
            day_of_week: dayOfWeek,
            week_of_month: weekOfMonth,
          }),
        },
      ]
      const topOptionValues = topOptions.map((option) => option.value)
      setSelectOptions([
        ...topOptions,
        ...rest.filter((option) => !topOptionValues.includes(option.value)),
      ])
    }
  }

  // Set didMount to control which effects run on initial render
  useEffect(() => {
    const $startDateInput = document.querySelector(
      `input[name="start_date"], input[name="date"]`
    )
    if ($startDateInput) {
      updateOptions($startDateInput.value)
      $startDateInput.addEventListener("change", (e) => {
        updateOptions(e.target.value)
      })
    }

    setDidMount(true)
  }, [])

  useEffect(() => {
    const handleDocClickFocus = (event) => {
      const refContainsEvent =
        containerRef.current && containerRef.current.contains(event.target)

      if (!refContainsEvent && expanded) {
        setExpanded(false)
      } else if (refContainsEvent && !expanded) {
        setExpanded(true)
      }
    }

    document.addEventListener("focusin", handleDocClickFocus, false)
    document.addEventListener("click", handleDocClickFocus, false)

    return () => {
      document.removeEventListener("focusin", handleDocClickFocus)
      document.removeEventListener("click", handleDocClickFocus)
    }
  }, [expanded, containerRef])

  // Clear the input whenever the menu is closed without a valid selection
  useEffect(() => {
    if (!didMount) return
  }, [expanded])

  // Remove focused index whenever options change
  useEffect(() => {
    const selectOptionsMap = selectOptions.reduce(
      (m, option) => ({ ...m, [option.value]: option }),
      {}
    )
    setSelectedOptions(
      selectedOptions.map((option) =>
        Object.keys(selectOptionsMap).includes(option.value)
          ? selectOptionsMap[option.value]
          : option
      )
    )
    setFocusedIndex(-1)
  }, [selectOptions])

  // Whenever focused index changes, update the document focus
  useEffect(() => {
    if (!didMount) return
    if (focusedIndex >= 0 && containerRef.current) {
      containerRef.current
        .querySelector(`li:nth-child(${focusedIndex + 1})`)
        .focus()
    } else if (containerRef.current) {
      containerRef.current.querySelector("input[role='combobox']").focus()
    }
  }, [focusedIndex])

  useEffect(() => {
    const modalMount = document.getElementById("modal-mount")
    render(openOccurrenceModal ? occurrenceModal : ``, modalMount)
  }, [openOccurrenceModal])

  // Implement select-like keyboard controls
  const onKeydown = (e) => {
    if (["ArrowDown", "ArrowRight"].includes(e.code)) {
      e.preventDefault()
      if (focusedIndex < selectOptions.length - 1) {
        setFocusedIndex(focusedIndex + 1)
      }
    } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
      e.preventDefault()
      if (focusedIndex > -1) {
        setFocusedIndex(focusedIndex - 1)
      }
    } else if (e.code === "Enter") {
      e.preventDefault()
      if (focusedIndex >= 0) {
        updateSelected(selectOptions[focusedIndex])
      }
    } else if (e.code === "Space") {
      if (focusedIndex >= 0) {
        e.preventDefault()
        updateSelected(selectOptions[focusedIndex])
      }
    }
  }

  const onRemoveOption = (removeValue) => {
    setSelectedOptions(
      selectedOptions.filter(({ value }) => value !== removeValue)
    )
  }

  const updateSelected = (option) => {
    const selectedValues = selectedOptions.map((option) => option.value)
    if (!selectedValues.includes(option.value)) {
      setSelectedOptions([...selectedOptions, option])
    }
    setFocusedIndex(-1)
  }

  const occurrenceModal = CustomOccurrenceModal({
    onClose: () => {
      setOpenOccurrenceModal(false)
    },
    onSubmit: updateSelected,
  })

  return html`
    <div
      class="autocomplete-field"
      @keydown=${onKeydown}
      ?ref=${ref(containerRef)}
    >
      <div class="autocomplete-results">
        ${repeat(
          selectedOptions,
          ({ value }) => value,
          ({ value }, idx) => html` <input
            type="hidden"
            name=${name}
            id="id_${name}_${idx}"
            .value="${value}"
          />`
        )}
        <div class="autocomplete-input relative flex flex-row items-center">
          <div
            class="input"
            aria-expanded=${expanded.toString()}
            aria-owns="${name}__listbox"
            id="id_${name}"
            role="combobox"
          >
            ${selectedOptions.length === 0 ? placeholder : ``}
            <div class="autocomplete-selections">
              ${MultipleSelectValues({
                selectedOptions,
                onDelete: onRemoveOption,
              })}
            </div>
          </div>
        </div>
        <ul
          style="max-height:none"
          role="listbox"
          id="${name}__listbox"
          class="scroll-y-no-scrollbar ${expanded ? `block` : `hidden`}"
        >
          ${repeat(
            visibleOptions,
            ({ value }) => value,
            ({ label, value }, index) => html`
              <li
                aria-selected=${(index === focusedIndex).toString()}
                class="m-0 p-4 cursor-pointer"
                tabindex="-1"
                role="option"
                data-option-value=${value}
                @click=${(e) => {
                  e.preventDefault()
                  updateSelected({ label, value })
                }}
              >
                ${label}
              </li>
            `
          )}
          <li
            aria-selected=${(visibleOptions.length === focusedIndex).toString()}
            class="m-0 p-4 cursor-pointer"
            tabindex="-1"
            role="option"
            @click=${() => setOpenOccurrenceModal(true)}
          >
            Custom
          </li>
        </ul>
      </div>
    </div>
  `
})

export default function renderOccurrenceInput(el) {
  const mount = document.createElement("div")
  const options = [...el.querySelectorAll("option")].map((option) => ({
    label: option.innerText.trim(),
    value: option.value,
    selected: option.hasAttribute("selected"),
  }))
  render(
    OccurrenceInput({
      options,
      name: el.name,
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
